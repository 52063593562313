
.div {
    margin-top: 1000px;
}

.text-section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    background: #222222;
}

.text-section .reveal {
    position: relative;
    color: #222;
    font-size: 10em;
    padding: 0 10px;
    transform-origin: left;
    transform: scale(0);
    transition: .8s;
    transition-delay: .8s;
}

.text-section-reveal {
    font-size: 300px;
    color: #ffffff;
    margin-top: 50px;
    margin-left: 0;
    opacity: .5;
    position: absolute;
    top: 0;
    right: 0;
    overflow-x: hidden;
}

@media screen and (max-width: 1500px) {
    .text-section-reveal {
        font-size: 200px;
    }
}

@media screen and (max-width: 900px) {
    .text-section-reveal {
        font-size: 150px;
    }
}

@media screen and (max-width: 580px) {
    .text-section-reveal {
        font-size: 100px;
    }
}

@media screen and (max-width: 400px) {
    .text-section-reveal {
        font-size: 75px;
    }
}

.text-section .reveal.active {
    transform: scaleX(1);
    transition-delay: 0s;
}

.text-section .reveal:nth-child(2) {
    font-size: 5em;
    letter-spacing: 2px;
    display: inline-block;
    margin-top: 5px;
}

.text-section .reveal:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #222222;
    transform-origin: right;
    transition: .8s;
    transition-delay: 0s;
}

.text-section .reveal:nth-child(2):before {
    background: #ffeb3b;
}

.text-section .reveal.active:before {
    transform: scaleX(0);
    transition-delay: .8s;
}

