@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');

.main-parallax  {
    background-color: #222222;
}

.section-section {
    position: relative;
    padding: 10vw 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

.image-container{
    z-index: 2;
    width: 42vw;
    height: 58vw;
    margin: 0 auto;
    overflow: hidden;
}

.image-container__inner{
    width: 100%;
    height: 100%;
}

.parallax-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.2);
}

.text-container{
    position: absolute;
    z-index: 1;
    top: 60%;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translateY(-50%);
    border-top: 1px solid #f6f6f6;
    border-bottom: 1px solid #f6f6f6;
}

.text-container__front {
    z-index: 3;
}
@media screen and (max-width: 700px) {
    .text-container__front {
        top: 45%;
    }
}


.text-container__back {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translateY(-50%);
    border-top: 1px solid #f6f6f6;
    border-bottom: 1px solid #f6f6f6;
}

@media screen and (max-width: 700px) {
    .text-container__back {
        top: 70%;
    }
}

.text-container__middle {
    position: absolute;
    z-index: 1;
    top: 20%;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translateY(-50%);
    border-top: 1px solid #f6f6f6;
    border-bottom: 1px solid #f6f6f6;
}



.text-container__front > .parallax-text{
    border-top: 1px solid #f6f6f6;
    border-bottom: 1px solid #f6f6f6;

}

.parallax-text {
    position: relative;
    font-family: 'Couture Bold', sans-serif;
    font-size: 10vw;
    line-height: 10vw;
    text-transform: uppercase;
    white-space: nowrap;
    color: #f6f6f6;
    margin: 0;
}

@media screen and (max-width: 700px) {
    .parallax-text {
        font-size: 15vw;
        line-height: 15vw;
    }
}

.parallax-text-second {
    color: transparent;
    -webkit-text-stroke: 0.2vw #f6f6f6;
}

