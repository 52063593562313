* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: url('https://www.somoswaka.com/wp-content/uploads/2017/10/WAKA-cursor.png') 25 30, auto;
}


@font-face {
  font-family: 'Azonix';
  src: local('Azonix'), url("./assets/fonts/Azonix.otf") format('opentype');
}
@font-face {
  font-family: 'Couture Bold';
  src: local('Couture Bold'), url("./assets/fonts/couture-bld.otf") format('opentype');
}


body {
  margin: 0;
  font-family: 'Couture Bold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
  color: #222222;
  overflow-x: hidden;
}


::selection {
  background: transparent;
  color: #fddb3a;
}

::-webkit-scrollbar {
  display: none;
}

.overlay {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 99;
}


.second {
  background: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.intro-image {
  width: 100%;
  height: 100%;
}

progress {
  position: fixed;
  top: 0;
  left: 0;
  appearance: none;
  width: 100%;
  height: 50px;
  border: none;
  background: #afafaf;
  z-index: 10000;
}
progress::-webkit-progress-bar {
  background: transparent;
}
progress::-webkit-progress-value {
  background: #000;
}

.app-container {
  width: 100vw;
  height: 100vh;
}