@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: url('https://www.somoswaka.com/wp-content/uploads/2017/10/WAKA-cursor.png') 25 30, auto;
}


@font-face {
  font-family: 'Azonix';
  src: local('Azonix'), url(/static/media/Azonix.9091792c.otf) format('opentype');
}
@font-face {
  font-family: 'Couture Bold';
  src: local('Couture Bold'), url(/static/media/couture-bld.b144f2b3.otf) format('opentype');
}


body {
  margin: 0;
  font-family: 'Couture Bold', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
  color: #222222;
  overflow-x: hidden;
}


::selection {
  background: transparent;
  color: #fddb3a;
}

::-webkit-scrollbar {
  display: none;
}

.overlay {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 99;
}


.second {
  background: #222222;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.intro-image {
  width: 100%;
  height: 100%;
}

progress {
  position: fixed;
  top: 0;
  left: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 100%;
  height: 50px;
  border: none;
  background: #afafaf;
  z-index: 10000;
}
progress::-webkit-progress-bar {
  background: transparent;
}
progress::-webkit-progress-value {
  background: #000;
}

.app-container {
  width: 100vw;
  height: 100vh;
}
.main-parallax  {
    background-color: #222222;
}

.section-section {
    position: relative;
    padding: 10vw 0;
    overflow: hidden;
    width: 100vw;
    height: 100vh;
}

.image-container{
    z-index: 2;
    width: 42vw;
    height: 58vw;
    margin: 0 auto;
    overflow: hidden;
}

.image-container__inner{
    width: 100%;
    height: 100%;
}

.parallax-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.2);
}

.text-container{
    position: absolute;
    z-index: 1;
    top: 60%;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translateY(-50%);
    border-top: 1px solid #f6f6f6;
    border-bottom: 1px solid #f6f6f6;
}

.text-container__front {
    z-index: 3;
}
@media screen and (max-width: 700px) {
    .text-container__front {
        top: 45%;
    }
}


.text-container__back {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translateY(-50%);
    border-top: 1px solid #f6f6f6;
    border-bottom: 1px solid #f6f6f6;
}

@media screen and (max-width: 700px) {
    .text-container__back {
        top: 70%;
    }
}

.text-container__middle {
    position: absolute;
    z-index: 1;
    top: 20%;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    transform: translateY(-50%);
    border-top: 1px solid #f6f6f6;
    border-bottom: 1px solid #f6f6f6;
}



.text-container__front > .parallax-text{
    border-top: 1px solid #f6f6f6;
    border-bottom: 1px solid #f6f6f6;

}

.parallax-text {
    position: relative;
    font-family: 'Couture Bold', sans-serif;
    font-size: 10vw;
    line-height: 10vw;
    text-transform: uppercase;
    white-space: nowrap;
    color: #f6f6f6;
    margin: 0;
}

@media screen and (max-width: 700px) {
    .parallax-text {
        font-size: 15vw;
        line-height: 15vw;
    }
}

.parallax-text-second {
    color: transparent;
    -webkit-text-stroke: 0.2vw #f6f6f6;
}



menu a {
    color: white;
    text-decoration: none;
}

a {
    color: white;
    text-decoration: none;
}

#menuMenu {
    position: fixed;
    top: 5%;
    left: 2em;
    font-family: 'wide';
    text-transform: uppercase;
    z-index: 200;
}

#menuPurchase {
    position: fixed;
    top: 5%;
    right: 2em;
    font-family: 'wide';
    text-transform: uppercase;
    z-index: 100;
}

#menuLogo {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 80vw;
    z-index: 100;
    transition: all .5s cubic-bezier(0.33, 1, 0.68, 1);
}

.logoSmall {
    top: 5% !important;
    transform: translate(-50%, -50%) scale(.2) !important;
}

#menuLinks {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: black;
    z-index: 100;
    font-family: 'wide';
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    font-size: 2em;
    visibility: hidden;
}

#menuLinks a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    display: block;
    padding: 1em;
}

#box1 {
    height: 100vh;
    display: block;
    width: 100vw;
    overflow: hidden;
}

#flagWave {
    width: 100%;
    position: absolute;
    z-index: -10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#flagWaveGif {
    display: none;
}

#box2 {
    position: relative;
    display: block;
    z-index: -1;
    height: 400vh;
    background-color: #222222;
}

#s2d0 {
    background-image: url(data:image/jpeg;base64,);
    -webkit-filter: brightness(30%);
            filter: brightness(30%);
    position: fixed;
    background-size: cover;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
}

#s2d1, #s2d2, #s2d3, #s2d4, #s2d5 {
    color: #f6f6f6
;
}

.s2 {
    position: fixed;
    font-size: 3em;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 90vw;
    left: 5vw;
    opacity: 0;
}

#box3 {
    height: 200vh;
    display: block;
    z-index: -10;
    position: relative;
    overflow: hidden;
}

#s3d1 {
    text-transform: uppercase;
    text-align: center;
    font-size: 3em;
    position: fixed;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    visibility: hidden;
}

#s3names {
    position: fixed;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    font-size: 5.5vmin;
    text-align: justify;
}

@media screen and (max-width: 700px) {
    #s3names {
        font-size: 8vmin;
    }
}

#box4 {
    display: block;
    overflow-x: hidden;
}

#box4black {
    width: 100vw;
    height: 250vh;
    margin-top: -10px;
}

#box4 img {
    width: 110vw;
    min-width: 110vw;
    min-height: 120vh;
    margin: 0;
}

#box4red {
    background-color: #98302f;
    width: 100vw;
    height: 300vh;
    margin-top: -10px;
}

.s4 {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 70vw;
    transform: translate(-50%, -50%);
    font-size: 3em;
    opacity: 0;
}

.s4b {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 70%;
    transform: translate(-50%, -50%);
    font-size: 3em;
    opacity: 0;
    color: black;
}

.wide {
    font-family: 'wide';
    font-size: .85em;
    text-transform: uppercase;
}

.red50 {
    font-family: 'Red50';
}

.red70 {
    font-family: 'Red70';
}

#box5 {
    height: 600vh;
    display: block;
}

#s5flagcontainer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    perspective-origin:  center center;
    perspective:100vw;
    opacity: 0;
    font-size: 1.3em;
}

#s5flagcontainer img {
    width: 100%;
    height: auto;
}


#s5redstripe {
    background-color: #98302f;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
}

#s5blacktop {
    position: fixed;
    width: 100%;
    bottom: 100%;
    transform-origin: bottom;
    transform: scaleY(.33);
}

#s5blacktop::before {
    background-color: black;
    width: 100%;
    height: 100vh;
    position: absolute;
    bottom: 99%;
    content: "";
}

#s5blackbottom {
    position: fixed;
    width: 100%;
    top: 100%;
    transform-origin: top;
    transform: scaleY(.33);
}

#s5blackbottom img {
    transform: scaleY(-1);
}

#s5blackbottom::before {
    background-color: black;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 99%;
    content: "";
}

#s5yellowtop {
    position: fixed;
    width: 100%;
    bottom: 100%;
    transform-origin: bottom;
    transform: scaleY(.34);
    z-index: -1;
}

#s5yellowbottom img {
    width: 100%;
}

#s5yellowbottom {
    position: fixed;
    width: 100%;
    top: 100%;
    transform-origin: top;
    transform: scaleY(.34);
    z-index: -1;
}

#s5yellowbottom img {
    width: 100%;
    transform: scaleY(-1);
}

.s5t {
    position: fixed;
    width: 30vw;
    top: 20%;
    right: 10%;
    opacity: 0;
}

.s5b {
    position: fixed;
    width: 30vw;
    top: 80%;
    right: 10%;
    opacity: 0;
}

#box6 {
    height: 150vh;
    display: block;
}

#s6d1 {
    position: fixed;
    top: 55%;
    left: 25%;
    right: 25%;
    transform: translateY(-50%);
    opacity: 0;
}

#s6d1 img {
    width: 100%;
}

#s6d2 {
    position: absolute;
    width: 30vw;
    top: 1%;
    left: 50%;
    opacity: 1;
    text-align: center;
    transform: translateX(-50%);
}

#box7 {
    height: 400vh;
    position: relative;
}

#s7d1{
    opacity: 0;
    visibility: hidden;
}

#s7d2 {
    opacity: 0;
    visibility: hidden;
}

#bjfVideo {
    position: fixed;
    width: 80%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

#videoSound {
    color: white;
    position: fixed;
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'wide';
    text-transform: uppercase;
    cursor: pointer;
}

.outline {
    color: black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.s7 {
    position: fixed;
    font-size: 4em;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    width: 100vw;
    opacity: 0;
}

#box8 {
    padding-top: 10vh;
    height: 120vh;
    /*background-image: url('flag_halftone.png');*/
    background-size: cover;
    background-position: center center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

#purchase {
    font-size: 3em;
    font-family: 'wide';
    text-transform: uppercase;
    padding: 1em;
    border-radius: 999px;
    background-color: black;
    border: 0px;
    color: white;
    text-decoration: none;
}

#purchase::after {
    content: "*All proceeds go to Black Lives Matter.";
    position: absolute;
    left: 50%;
    transform: translate(-50%,4em);
    width: inherit;
    font-size: 1rem;
    font-family: "Red35";
    text-transform: none;
}

#purchase:hover {
    background-color: white;
    color: black;
}

#shareLinks li {
    font-size: 2em;
    padding: .5em;
    overflow-y: hidden;
    height: 1em;
}

#shareToggle {
    cursor: pointer;
}

#actualShareButtons {
    justify-content: center;
    display: none;
}

.resp-sharing-button {
    transition: 25ms ease-out;
    padding: 0 0.75em;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif
}

.resp-sharing-button__icon svg {
    width: 1em;
    height: 1em;
    margin-right: 0.4em;
    vertical-align: top;
    fill: white;
}

#box9 {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    font-size: 2em;
}

#box10 {
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#box10 img {
    width: 60%;
    margin-left: 50%;
    transform: translateX(-50%);
}

.show {
    display: block;
    background-color: red;
    width: 1px;
    height: 1px;
}

#flag {
    margin-top: 100vh;
}

/* Landscape */
@media only screen
and (orientation: portrait) {

    html {
        font-size: 3vw;
    }

    #flagWave {
        display: none;
    }

    #flagWaveGif {
        display: block;
        height: 50%;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: -20;
    }



    .logoSmall {
        top: 5% !important;
        transform: translate(-50%, -35%) scale(.4) !important;
    }


    #menuPurchase {
        display: none;
    }

    .s2 {
        font-size: 3em;
    }

    #s3d1 {
        font-size: 10vw;
    }

    #s3names {
        font-size: 5vmin;
    }

    #box4black {
        height: 150vh;
    }


    .s4b {
        width: 90vw;
    }

    .s5t {
        position: fixed;
        width: 90vw;
        top: 20vh;
        right: 5vw;
        opacity: 0;
        text-align: center;
        font-size: 1.5em;
    }

    .s5b {
        position: fixed;
        width: 90vw;
        top: auto;
        bottom: 15vh;
        right: 5vw;
        opacity: 0;
        text-align: center;
        font-size: 1.5em;
    }

    #s6d1 {
        position: fixed;
        top: 60%;
        left: 5%;
        right: 5%;
        transform: translateY(-30%);
        opacity: 0;
    }


    #s6d2 {
        font-size: 1.5em;
        width: 80vw;
        top: -50%;
    }

    #bjfVideo {
        width: 90%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    #box8 {
        background-size:cover;
        height: 120vw;
    }

    #purchase {
        padding-top: 3em;
        padding-bottom: 3em;
        font-size: 1.8em;
    }

    #purchase::after {
        font-size: 1rem;
        width: 100%;
    }

    #shareLinks {
        font-size: 1em;
    }

    #box9 {
        height: auto;
        padding-top: 20vw;
        padding-bottom: 20vw;
    }

    #box10 {
        height: auto;
        padding-bottom: 20vw;
    }
}

.gridBlock {
    opacity: 0;
}


/* Grid almost fully pulled from https://www.rino-pelle.com/ */
.grid-container {
    width: 100vw;
    height: 300vh;
    position: relative;
    margin-bottom: 500px;
}

@media screen and (max-width: 1250px) {
    .grid-container {
        /*margin-bottom: 200px;*/
    }
}

@media screen and (max-width: 800px) {
    .grid-container {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 580px) {
    .grid-container {
        margin-bottom: 100px;
    }
}

.grid {
    top: 0;
    left: 0;
    z-index: 1;
    width: 70vw;
    height: 60vw;
    margin: 0 calc(100vw / 20 * 3);
    position: absolute;

}
@media only screen and (max-width: 580px) {
    .grid {
        margin: 0;
        width: 100vw;
        height: 89.444vw;
    }
}
.gridBlock {
    background-size: cover;
    background-color: darkgray;
}
.gridLayer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    will-change: transform;
    transform-origin: 44.951% 50%;
}
.gridLayer:nth-child(1) .gridBlock {
    top: 5vw;
    left: 20vw;
    width: 10vw;
    height: 10vw;
}
@media only screen and (max-width: 580px) {
    .gridLayer:nth-child(1) .gridBlock {
        top: 11.111vw;
        left: 23.889vw;
        width: 15vw;
        height: 15vw;
    }
}
.gridLayer:nth-child(2) .gridBlock {
    top: 0;
    left: 32.5vw;
    width: 15vw;
    height: 15vw;
}
@media only screen and (max-width: 580px) {
    .gridLayer:nth-child(2) .gridBlock {
        top: 0;
        left: 43.333vw;
        width: 26.389vw;
        height: 26.389vw;
    }
}
.gridLayer:nth-child(3) .gridBlock {
    left: 0;
    top: 25vw;
    width: 15vw;
    height: 15vw;
}
@media only screen and (max-width: 580px) {
    .gridLayer:nth-child(3) .gridBlock {
        top: 30.278vw;
        left: 0;
        width: 26.389vw;
        height: 26.389vw;
    }
}
.gridLayer:nth-child(4) .gridBlock {
    top: 17.5vw;
    left: 17.5vw;
    width: 30vw;
    height: 30vw;
}
@media only screen and (max-width: 580px) {
    .gridLayer:nth-child(4) .gridBlock {
        top: 30.278vw;
        left: 30.278vw;
        width: 39.444vw;
        height: 39.444vw;
    }
}
.gridLayer:nth-child(5) {
    z-index: 1;
}
.gridLayer:nth-child(5) .gridBlock {
    top: 20vw;
    left: 50vw;
    width: 5vw;
    height: 5vw;
}
@media only screen and (max-width: 580px) {
    .gridLayer:nth-child(5) .gridBlock {
        top: 23.889vw;
        left: 73.889vw;
        width: 15.278vw;
        height: 15.278vw;
    }
}
.gridLayer:nth-child(5) a {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gridLayer:nth-child(5) svg {
    width: 1.75vw;
}
@media only screen and (max-width: 580px) {
    .gridLayer:nth-child(5) svg {
        width: 4.722vw;
    }
}
.gridLayer:nth-child(6) .gridBlock {
    left: 50vw;
    top: 27.5vw;
    width: 20vw;
    height: 20vw;
}
@media only screen and (max-width: 580px) {
    .gridLayer:nth-child(6) .gridBlock {
        top: 43.889vw;
        left: 73.889vw;
        width: 26.389vw;
        height: 26.389vw;
    }
}
.gridLayer:nth-child(7) .gridBlock {
    left: 10vw;
    top: 42.5vw;
    width: 5vw;
    height: 5vw;
}
@media only screen and (max-width: 580px) {
    .gridLayer:nth-child(7) .gridBlock {
        display: none;
    }
}
.gridLayer:nth-child(8) .gridBlock {
    bottom: 5vw;
    left: 42.5vw;
    width: 5vw;
    height: 5vw;
}
@media only screen and (max-width: 580px) {
    .gridLayer:nth-child(8) .gridBlock {
        display: none;
    }
}
.gridLayer:nth-child(9) .gridBlock {
    bottom: 0;
    left: 50vw;
    width: 10vw;
    height: 10vw;
}
@media only screen and (max-width: 580px) {
    .gridLayer:nth-child(9) .gridBlock {
        top: 73.889vw;
        left: 58.889vw;
        width: 15.278vw;
        height: 15.278vw;
    }
}
.gridBlock {
    display: block;
    position: absolute;
}

/*.container {*/
/*    width: 600%;*/
/*    height: 100%;*/
/*    display: flex;*/
/*    flex-wrap: nowrap;*/
/*}*/

/*.panel {*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*}*/

/*.red {*/
/*    background-color: #222;*/
/*}*/

/*.orange {*/
/*    background-color: #f6f6f6;*/
/*}*/

/*.purple {*/
/*    background-color: #222;*/
/*}*/

/*.green {*/
/*    background-color: #f6f6f6;*/
/*}*/

/*.green {*/
/*    background-color: #222;*/
/*}*/

/*#maraim {*/
/*    position: absolute;*/
/*    top: 50px;*/
/*    right: 0;*/
/*    z-index: -1;*/
/*}*/


.div {
    margin-top: 1000px;
}

.text-section {
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    background: #222222;
}

.text-section .reveal {
    position: relative;
    color: #222;
    font-size: 10em;
    padding: 0 10px;
    transform-origin: left;
    transform: scale(0);
    transition: .8s;
    transition-delay: .8s;
}

.text-section-reveal {
    font-size: 300px;
    color: #ffffff;
    margin-top: 50px;
    margin-left: 0;
    opacity: .5;
    position: absolute;
    top: 0;
    right: 0;
    overflow-x: hidden;
}

@media screen and (max-width: 1500px) {
    .text-section-reveal {
        font-size: 200px;
    }
}

@media screen and (max-width: 900px) {
    .text-section-reveal {
        font-size: 150px;
    }
}

@media screen and (max-width: 580px) {
    .text-section-reveal {
        font-size: 100px;
    }
}

@media screen and (max-width: 400px) {
    .text-section-reveal {
        font-size: 75px;
    }
}

.text-section .reveal.active {
    transform: scaleX(1);
    transition-delay: 0s;
}

.text-section .reveal:nth-child(2) {
    font-size: 5em;
    letter-spacing: 2px;
    display: inline-block;
    margin-top: 5px;
}

.text-section .reveal:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #222222;
    transform-origin: right;
    transition: .8s;
    transition-delay: 0s;
}

.text-section .reveal:nth-child(2):before {
    background: #ffeb3b;
}

.text-section .reveal.active:before {
    transform: scaleX(0);
    transition-delay: .8s;
}


